body {
  font-family: "Sora", sans-serif !important;
  font-size: 11px !important;
  color: #495057;
  /* transition: 0.3s; */
  scroll-behavior: smooth;
}
.main-dashboard .dashboard-topbar-wrap a > img {
  height: 65px;
  position: relative;
  left: -20px;
}
::placeholder {
  font-size: 12px;
}
.filter-box.add-division-btn button {
  border-color: #ced4da;
  font-size: 14px;
}
select.form-select {
  font-size: 14px;
}
p {
  margin-bottom: 0;
}
.challan-list{
  vertical-align: middle;
}
.notification-info{
  cursor: pointer;
  /* margin-bottom: 11px; */
  border-bottom: 1px solid #ccc;
  padding: 19px 0px 0px 19px;
}
.bullet-icon{
  position: relative;
  left: -57px;
  right: 0px;
  top: -6px;
}
.notification-dropdown{
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 15%);
}
.notification-inner-area{
  background: #FAFDFF;
}
.border_top{
  border: 1px dashed #808080;
  margin-bottom: 8px;
}
img.attendance-user-image.img-fluid.attendance-img {
  height: 40px;
  border-radius: 20px;
  width: 40px;
}
